<template>
  <div class="billingUpload">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-4">
        <v-container>
          <label for="datePicker">Filter date</label>
          <v-row>
            <v-col cols="4">
              <input
                type="date"
                class="input"
                name="datePicker"
                id="datePicker"
                v-model="datePicker"
              />
            </v-col>
            <v-col cols="4">
              <v-btn
                color="primary"
                class="mb-2 ml-2"
                :disabled="loading"
                @click="getSortedBillingImportErrors()"
                >Get Sorted Billing Import Errors</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <div v-if="data.length > 0">
        <v-card class="pa-2">
          <v-card-title>
            {{ title }}
          </v-card-title>
          <v-row class="pa-2">
            <v-col cols="6">
              <ExportButtons
                :headers="headers"
                :data="data"
                :title="title + datePicker"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="5"
            :search="search"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#B22222"
                :disabled="loading"
                @click="deleteEntry(item)"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import SortedUpload from "@/services/SortedUpload.js";
import ExportButtons from "@/components/ExportButtons";
export default {
  name: "SortedBillingImportErrors",
  components: { ExportButtons },
  data() {
    return {
      title: "Sorted Billing Import Errors",
      // Snackbar
      snackbar: false,
      text: "",
      response: null,

      search: "",
      loading: false,

      datePicker: "",

      data: [],

      headers: [
        { text: "Order number", value: "order_number" },
        { text: "Error", value: "error" },
        { text: "Date", value: "date" },
        { text: "", value: "actions" },
      ],
    };
  },

  methods: {
    async getSortedBillingImportErrors() {
      this.loading = true;
      SortedUpload.getSortedBillingImportErrors(this.datePicker)
        .then(
          ((data) => {
            this.$set(this, "data", data);
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully Loaded Data`;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },

    deleteEntry(entry) {
      if (
        confirm(
          "Confirm Deletion of Entry with Order Number: " + entry.order_number
        )
      ) {
        SortedUpload.deleteSortedBillingError({ ids: [entry.id] })
          .then(() => {
            this.getSortedBillingImportErrors();
            this.loading = false;
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = `${error.message}`;
            this.loading = false;
            return;
          });
      }
    },
 
  },
};
</script>
<style lang="scss" scoped>
.description {
  margin-bottom: 30px;
}
.error {
  background-color: red;
}
</style>